@import '../../../scss/variables';
@import '../../../scss/mixins';

.day {
  display: flex;
  flex-direction: column;
  border-left: 2px solid #fff;
  position: relative;

  &--past {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__head {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #eee;
    color: #000;
    height: 4rem;
    border-bottom: 2px solid #fff;

    @include media-breakpoint-down(lg) {
      font-size: 0.875rem;
    }

    @include media-breakpoint-down(lg) {
      height: 3rem;
    }
  }

  &__slot {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 4%;
  }

  input {
    display: none;
  }
}
.day-head {
  &__day {
    font-weight: 500;
    font-size: 1.25rem;
    text-transform: lowercase;

    @include media-breakpoint-down(lg) {
      font-size: 1rem;
    }
  }
}
.slot {
  font-size: 0.875rem;
  background: #efefef;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem 0 0.5rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding: 0 1rem 0 0.375rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 0.75rem;
  }

  &--notavailable {
    background: #f6f6f6;
    color: #aaa;
  }
  &--available {
    color: #004877;
    background: #eff5f8;
    padding: 0 0.5rem;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      padding: 0 1rem 0 0.375rem;
    }

    &:hover {
      background: #cee1ee;

      .slot__icon {
        transform: scale(1.1);
      }
    }
  }
  &--discount {
    padding: 0 2rem 0 0.5rem;

    @include media-breakpoint-down(lg) {
      padding: 0 1rem 0 0.375rem;
    }
  }
  &--reserved {
    color: #633d21;
    background: #f4ebda;
  }
  &--booked {
    color: #2a5135;
    background: #cbdacf;
  }
  &--selected {
    color: #fff;
    background: #004877;

    &:hover {
      background: #004877;
    }
  }
  &--selected &__discount {
    display: none;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    stroke-width: 1.75;
    transition: all 0.2s;

    @include media-breakpoint-down(lg) {
      width: 0.875rem;
      min-width: 0.875rem;
      height: 0.875rem;
    }
  }
  &__label {
    flex-grow: 1;
    padding-left: 0.25rem;
  }
  &__discount {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffd200;
    color: #000;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0 0.25rem 0 0;

    &:before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 0.5rem;
      background: #ffd200;
      transform-origin: 0 100%;
      transform: skewX(-15deg);
    }
  }
}
.discount {
  position: relative;
}
