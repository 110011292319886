@import '../../scss/variables';
@import '../../scss/mixins';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @include media-breakpoint-down(lg) {
    padding: 0 1rem;
  }
}

body {
  padding: 0;
  margin: 0;
}

a {
  color: #000;
}

.app {
  font-family: 'Exo 2', sans-serif;
  padding: 0 0 4rem;
  font-weight: 300;

  &__header {
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);
    margin: 0 0 2rem;
    position: relative;
    padding: 0.5rem 0;

    @include media-breakpoint-down(md) {
      margin: 0 0 1rem;
    }
  }

  &__message {
    font-size: 1rem;
    padding: 1rem 2rem 1rem 1rem;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0 0 1rem;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 0.75rem 2rem 0.75rem 0.75rem;
    }

    &-close {
      position: absolute;
      right: 0;
      top: 0;
      box-sizing: content-box;
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
      padding: 0.5rem;
    }

    &-icon {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      stroke-width: 1.25;

      @include media-breakpoint-down(md) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    &-text {
      padding: 0 0 0 1rem;
      font-weight: 400;

      @include media-breakpoint-down(md) {
        padding: 0 0 0 0.75rem;
      }

      b {
        font-weight: bold;
      }
      p {
        margin: 0;

        + p {
          margin: 0.25rem 0 0;
        }
      }
    }
    &--testing {
      background: lighten(#ffd200, 40);
    }
  }

  &__logo {
    width: 5rem;
    height: auto;
    display: block;
    margin: 0.5rem auto 0;

    @include media-breakpoint-down(md) {
      margin: 3.5rem auto 0;
    }
  }

  &__back {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 2rem;
    border-right: 1px solid #eee;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      width: 50%;
      font-size: 0.875rem;
      padding: 0 1rem;
      height: 3rem;
      border-bottom: 1px solid #eee;
    }

    &-icon {
      stroke-width: 1;
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      margin: 0 0.5rem 0 0;
    }
  }
  &__phone {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 2rem;
    border-left: 1px solid #eee;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      width: 50%;
      font-size: 0.875rem;
      border-left: none;
      padding: 0 1rem;
      height: 3rem;
      border-bottom: 1px solid #eee;
    }

    &-icon {
      stroke-width: 1;
      width: 1.25rem;
      min-width: 1.25rem;
      height: 1.25rem;
      margin: 0 0.5rem 0 0;
    }
  }

  &__selector {
    height: 40vh;
    transition: height 0.3s;

    @include media-breakpoint-down(md) {
      height: 70vh;
    }

    &--compact {
      height: 6rem;

      @include media-breakpoint-down(md) {
        height: auto;
      }
    }
  }
  &__mini-cart {
    margin: 0.5rem 0 0;
    float: right;

    @include media-breakpoint-down(md) {
      float: none;
      margin: 0;
      position: fixed;
      border-left: none !important;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }
  }
}

/* Modal window */
body.is--modal-open {
  overflow: hidden;

  .l-header {
    position: static;
  }
}

/* Preloaders */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  &--units {
    height: 40vh;
  }
  &--global {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: #fff;
  }

  &--days-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
  &--days {
    position: static;
    flex-grow: 1;
    width: 100%;
    padding: 0 0 2rem;
  }
}

/* Form elements */
.form {
  &-item {
    margin: 0 0 0.5rem;

    &--error {
      color: red;

      .form {
        &-textarea,
        &-email,
        &-text {
          border-bottom: 1px solid red !important;
        }
      }
    }
  }
  &-textarea,
  &-email,
  &-text {
    font-family: 'Exo 2', sans-serif;
    background: #eee;
    border: none;
    border-style: none !important;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%;
    outline: none;
    border-bottom: 1px solid #eee !important;

    &:focus {
      border-bottom: 1px solid #333 !important;
    }
  }
  &-checkbox {
    display: inline-block !important;
    margin: 0 0.5rem 0 0;
    vertical-align: -0.125rem;
  }
  &-label {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 0.25rem;
    display: inline-block;

    &--checkbox {
      display: flex;

      input {
        margin: 0.25rem 0.5rem 0 0;
      }
    }

    a {
      text-decoration: underline;
    }

    sup {
      color: red;
    }
  }
}
.button {
  border: none;
  font-family: 'Exo 2', sans-serif;
  background: #444;
  font-size: 1rem;
  padding: 1rem;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #666;
  }

  &[disabled] {
    background: #666;
    cursor: not-allowed;
  }

  &--clear {
    padding: 0;
    background: none;
    color: #000;

    &:hover {
      background: none;
    }
  }
  &--small {
    padding: 0.5rem 1rem;

    @include media-breakpoint-down(md) {
      padding: 0.5rem;
    }
  }
}
.react-date-picker {
  width: 100%;

  &__inputGroup__input:invalid {
    background: none !important;
  }

  &__calendar {
    width: 100% !important;

    .react-calendar {
      border: 1px solid #eee;
      font-family: 'Exo 2', sans-serif;

      &__navigation {
        button {
          &[disabled] {
            background: none;
            color: #ccc;
          }
        }
      }

      &__tile {
        &:disabled {
          background: none;
          color: #ccc;
        }
        &:hover {
          background: #eee !important;
        }
        &--now {
          background: #eff5f8;

          &:hover {
            background: #eff5f8;
          }
        }
        &--active {
          background: #004877;

          &:hover {
            background: #004877;
          }
        }
      }
    }
  }

  &__button {
    width: 1.5rem;
    height: 1rem;
    margin: -0.125rem 0 0;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &__wrapper {
    font-family: 'Exo 2', sans-serif;
    background: #eee;
    border: none;
    border-style: none !important;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%;
    outline: none;
    border-bottom: 1px solid #eee !important;

    &:focus {
      border-bottom: 1px solid #333 !important;
    }
  }
}
