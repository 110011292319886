@import '../../../scss/variables';
@import '../../../scss/mixins';

.cart {
  display: flex;

  @include media-breakpoint-down(lg) {
    display: block;
  }

  &__empty {
    text-align: center;
    max-width: 30rem;
    margin: 0 auto;
    font-size: 1.25rem;

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 auto 1rem;
      stroke-width: 1;
    }
  }

  &__error {
    padding: 1rem;
    display: flex;
    margin: 0 0 1rem;
    background: #e1261c;
    color: #fff;

    @include media-breakpoint-down(md) {
      padding: 0.75rem;
    }

    &-icon {
      width: 2.5rem;
      height: 2.5rem;
      flex-shrink: 0;
      stroke-width: 1;

      @include media-breakpoint-down(md) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    &-message {
      padding: 0 0 0 1rem;
      font-weight: 400;

      @include media-breakpoint-down(md) {
        padding: 0 0 0 0.75rem;
      }
    }
    &-title {
      font-size: 1rem;
    }
    &-text {
      font-size: 0.875rem;
    }
  }

  &__options {
    padding: 0;
    margin: 0;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .option {
    display: flex;
    align-items: flex-start;
    padding: 0 2rem 0 1.5rem;
    font-size: 0.875rem;
    padding: 0.375rem 0;
    position: relative;

    &--error {
      color: #e1261c;
    }
    &__error {
      &-icon {
        position: absolute;
        right: 100%;
        margin: 0 0.25rem 0 0;
        top: 0.5rem;
        stroke-width: 2;
        width: 1rem;
        height: 1rem;
        color: #e1261c;
      }
    }

    .form-checkbox {
      margin: 0.125rem 0 0;
    }

    &__content {
      flex-grow: 1;
      margin: 0 0 0 0.5rem;
    }
    &__name {
      font-weight: 400;
      display: flex;

      &:after {
        @include dotted-connect(1rem);
      }
    }
    &__price {
      font-weight: 400;
    }
    &__description {
      font-size: 0.75rem;
      display: block;
      font-weight: 400;
      color: #666;
      margin: 0.125rem 0 0;
    }
  }

  &__message {
    font-size: 0.875rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0 0 1rem;

    @include media-breakpoint-down(md) {
      padding: 0.75rem;
    }

    &-icon {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      stroke-width: 1.25;

      @include media-breakpoint-down(md) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    &-text {
      padding: 0 0 0 1rem;
      font-weight: 400;

      @include media-breakpoint-down(md) {
        padding: 0 0 0 0.75rem;
      }

      b {
        font-weight: bold;
      }
      p {
        margin: 0;

        + p {
          margin: 0.25rem 0 0;
        }
      }
    }
    &--time {
      background: lighten(#ffd200, 40);
    }
    &--visitors {
      background: lighten(#ffd200, 40);
    }
  }

  &__unit {
    margin-bottom: 1rem;

    &-name {
      font-weight: 500;
      font-size: 1.25rem;
      margin: 0 0 0.25rem;
      padding: 0 0 0.25rem;
      border-bottom: 1px solid #eee;

      @include media-breakpoint-down(md) {
        font-size: 1.125rem;
      }
    }
    &-options-title {
      font-size: 1rem;
      font-weight: 400;
      padding: 0.75rem 0;
    }
    &-options-wrapper &-options {
      label {
        padding-left: 0;
      }
    }
    &-options {
      padding: 0.5rem 1.75rem 0 0.25rem;
    }
  }

  &__slots-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__slot {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-range {
      flex-grow: 1;
      font-weight: 400;
      display: flex;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }

      &:after {
        @include dotted-connect(1rem);
      }
    }
    &-discount {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #ffd200;
      color: #000;
      font-weight: 500;
      font-size: 0.75rem;
      padding: 0.25rem 0.25rem 0.25rem 0;
      margin: 0 0 0 0.75rem;

      @include media-breakpoint-down(md) {
        font-size: 0.625rem;
      }

      &:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 0.5rem;
        background: #ffd200;
        transform-origin: 0 100%;
        transform: skewX(-15deg);
      }
    }
    &-price {
      font-weight: 500;
      padding: 0 0.5rem 0 0.25rem;

      @include media-breakpoint-down(md) {
        font-size: 0.875rem;
      }
    }
    &-icon {
      stroke-width: 1.25;
      width: 1.25rem;
      height: 1.25rem;

      @include media-breakpoint-down(md) {
        width: 1rem;
        height: 1rem;
      }

      &--toggle {
        margin: 0 0.25rem -0.25rem 0;
        transition: all 0.2s;

        @include media-breakpoint-down(md) {
          margin: 0 0.25rem 0 0;
        }
      }
      &--error {
        color: #e1261c;
        margin: 0 0.25rem 0 0;
      }

      &--remove {
        color: #e1261c;
      }
    }
    &-options-list {
      width: 100%;
      font-size: 0.75rem;
      font-weight: 400;
      color: #666;
      display: flex;
      justify-content: space-between;
      padding: 0 1.75rem 0 1.5rem;

      @include media-breakpoint-down(md) {
        padding: 0 1rem 0 1.25rem;
      }

      &-price {
        white-space: nowrap;
        flex-grow: 1;
        text-align: right;
        display: flex;
        align-items: baseline;
        min-width: 20%;

        &:before {
          @include dotted-connect(0.75rem);
        }
      }
      &-option {
        &--error {
          color: #e1261c;
        }
      }
    }
    &-options-toggle {
      font-size: 0.75rem;
      width: 100%;
      margin: 0 0 0 1.5rem;
      font-weight: 400;
      color: #074976;

      @include media-breakpoint-down(md) {
        margin: 0 0 0 1.25rem;
      }

      span {
        border-bottom: 1px dashed currentColor;
        cursor: pointer;
      }
    }
    &-options {
      padding: 0.5rem 1.75rem 0 1.5rem;
      display: none;

      @include media-breakpoint-down(md) {
        padding: 0.5rem 1rem 0 1.25rem;
      }
    }
    &--expanded &-options {
      display: block;
    }
    &--expanded &-icon--toggle {
      transform: rotate(-180deg);
    }
    &--error {
      color: #e1261c;
    }
    &--error &-range {
      cursor: default;
    }
    &--error &-options-toggle {
      display: none;
    }
  }

  &__total {
    display: block;
    padding: 1rem 0 0;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: right;
    border-top: 1px solid #eee;

    @include media-breakpoint-down(md) {
      font-size: 1.125rem;
    }
  }

  &__order-info-form {
    width: 33%;
    min-width: 22rem;
    flex-shrink: 0;

    @include media-breakpoint-down(lg) {
      width: 100%;
      min-width: 0;
    }
  }

  &__order-details {
    position: relative;
    flex-grow: 1;
    padding: 0 2rem 0 0;

    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }

  &__submit-button {
    width: 100%;
    display: block;
    margin: 20px auto;

    .spinner {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
      margin: -1rem 0.5rem -1rem 0;

      &__path {
        stroke: #fff;
      }
    }
  }

  &-option-global {
    margin: 0.5rem 0 1rem;

    &__summary {
      display: flex;
      flex-wrap: wrap;
      margin: 0.75rem 0;

      &--expanded .cart__slot-icon--toggle {
        transform: rotate(-180deg);
      }

      &-title {
        flex-grow: 1;
        font-weight: 400;
        display: flex;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          font-size: 0.875rem;
        }

        &:after {
          @include dotted-connect(1rem);
        }
      }
      &-price {
        font-weight: 500;
        padding: 0 0.5rem 0 0.25rem;

        @include media-breakpoint-down(md) {
          font-size: 0.875rem;
        }
      }
    }

    &--relative {
      position: relative;
    }

    &--with-window &__name {
      &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 50%;
        margin: -1rem 0 0 0.25rem;
        z-index: 10;
        border-right: 0.75rem solid #fff;
        border-top: 0.75rem solid transparent;
        border-bottom: 0.75rem solid transparent;
      }
    }

    .spinner {
      width: 4rem;
      height: 4rem;
      margin: 0 auto 1rem;
    }

    &__window {
      position: absolute;
      background: #fff;
      box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);
      padding: 1.25rem 1rem 0;
      left: calc(100% - 1rem);
      transform: translate(2rem, -50%);
      width: 22rem;
      top: 1rem;
      display: flex;
      align-items: stretch;
      z-index: 5;

      @include media-breakpoint-down(lg) {
        left: auto;
        right: 15rem;
      }
      @include media-breakpoint-down(md) {
        left: 0;
        transform: translate(0, -50%);
        right: auto;
        width: 20rem;
      }

      &--loaded {
        top: 50%;
        max-height: 105%;
        transform: translate(0, -50%);

        @include media-breakpoint-down(lg) {
          left: auto;
          right: 13rem;
        }
        @include media-breakpoint-down(md) {
          left: 0;
          right: auto;
          max-height: 80vh;
          transform: translate(0, 0);
          top: 0;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          height: 2rem;
          background: linear-gradient(to bottom, rgba(#fff, 0), rgba(#fff, 1));
          z-index: 5;
        }
      }
    }

    &__button {
      font-size: 0.875rem;
      display: flex;
      align-items: center;

      &-icon {
        width: 1rem;
        height: 1rem;
        stroke-width: 2;
        margin: 0 0.5rem 0 0;
      }
    }
    &__name {
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;
    }
    &__empty {
      margin: 0.75rem 0 1.75rem 0;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      align-items: center;

      &-text {
        margin: 0.125rem 0 0;
      }
    }
    &__icon {
      stroke-width: 1.5;
      width: 1.25rem;
      height: 1.25rem;
      margin: 0 0.25rem 0 0;

      @include media-breakpoint-down(md) {
        width: 1rem;
        height: 1rem;
      }
    }
    &__label {
      display: block;
      font-weight: 500;
      margin: 0 0 0.5rem;
      font-size: 0.875rem;
    }
    &__date {
      font-size: 0.875rem;
      display: inline-block;
      margin: 0.25rem 0.5rem 0 0;
      font-weight: 400;
      color: #074976;

      span {
        border-bottom: 1px dashed currentColor;
        cursor: pointer;
      }
    }
    &__dates {
      font-size: 0.875rem;
      margin: 0 0 1rem;

      &-selector,
      &-current {
        display: block;
      }
      &-date {
        border: 1px solid #eee;
        padding: 0.25rem;
        margin: 0 0.25rem 0 0;
        cursor: pointer;
        display: inline-block;
      }
    }
    &__calendar {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &__slots {
      //display: flex;
      //flex-wrap: wrap;
      overflow: scroll;
      flex-grow: 1;
      margin: 0 -1.25rem 0 0;
      padding: 0 1.25rem 2rem 0;

      &-toggler {
        width: 100%;
        font-size: 0.875rem;
        font-weight: normal;
        margin: 0 0 0.5rem;
        color: #074976;
        text-align: center;

        span {
          border-bottom: 1px dashed currentColor;
          cursor: pointer;
        }
      }
    }
    &__slot {
      font-size: 0.875rem;
      padding: 0.125rem 0.25rem 0.125rem 0.375rem;
      //height: 1.25rem;
      margin: 1px;
      height: 1.75rem;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ccc;
      font-weight: 400;
      background: #fefefe;
      flex-grow: 1;
      flex-basis: 40%;

      &--available {
        color: #000;
        background: #eff5f8;
        cursor: pointer;
      }
      &-time {
        flex-grow: 1;
        text-align: left;
        margin: 0 0 0 0.25rem;
      }
      &-controls {
        display: flex;
        align-items: center;
        margin: -0.125rem -0.25rem -0.125rem 0;

        .button {
          font-size: 1.25rem;
          line-height: 1;
          padding: 0;
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          &[disabled] {
            background: #999;
          }
        }
      }

      &-icon {
        width: 1rem;
        height: 1rem;
        stroke-width: 1.5;
        color: #999;
        margin: 0.125rem 0 0;

        &--checked {
          stroke-width: 2;
          color: #004877;
        }
      }
      &-count {
        margin: 0 0.375rem;
        min-width: 2rem;
        line-height: 1.2;
      }
    }
  }
}
