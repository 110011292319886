@import "../../scss/variables";
@import "../../scss/mixins";

.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  &__overlay {
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
  }

  &__window {
    position: relative;
    z-index: 1;
    margin: 5vh auto;
    border: 1px solid #ddd;
    padding: 2em;
    width: 64em;
    max-width: 90vw;
    background: #fff;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.2);

    @include media-breakpoint-down(md) {
      padding: 2rem 1rem;
      max-width: 100vw;
      margin: 0;
    }
  }

  &__close {
    position: absolute;
    right: .25rem;
    top: .5rem;
    border: none;
    padding: 0.5em;
    background: none;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      right: 0;
      top: 0;
    }

    * {
      display: block;
    }
  }
}