@import '../../../scss/variables';
@import '../../../scss/mixins';

.order-confirmation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    display: block;
  }

  .options {
    width: 100%;
    margin: 0.25rem 0 0;
  }
  .option {
    display: flex;
    align-items: flex-start;
    padding: 0 2rem 0 1.5rem;
    font-size: 0.875rem;
    padding: 0.25rem 0;

    .form-checkbox {
      margin: 0.125rem 0 0;
    }

    &__content {
      flex-grow: 1;
    }
    &__name {
      font-weight: 400;
      display: flex;

      &:after {
        @include dotted-connect(1rem);
      }
    }
    &__price {
      font-weight: 500;
    }
    &__description {
      font-size: 0.75rem;
      display: block;
      font-weight: 400;
      color: #666;
      margin: 0.125rem 0 0;
    }
  }

  &__pane-1,
  &__pane-2 {
    flex-basis: 50%;
  }

  &__pane-1 {
    flex-grow: 1;
    padding-right: 1.5rem;

    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
  &__pane-2 {
    max-width: 22em;
    display: flex;
    flex-direction: column;
    margin-top: -1.5rem;

    @include media-breakpoint-down(lg) {
      max-width: none;
      margin-top: 1rem;
    }
  }
  &__actions {
    flex-grow: 1;
    display: flex;
    margin-top: 1.5rem;
    align-items: flex-end;
  }
  &__info {
    background: #f6f6f6;
    padding: 1.5rem 1.5rem 0.75rem;
  }
  &__note {
    padding: 1rem 1.5rem;
    margin: 1.5rem 0 0;
    background: lighten(#ffd200, 40);
    font-weight: 300;
    width: 100%;
    font-size: 0.875rem;

    ul {
      margin: 0 0 0 1em;
      padding: 0;

      li {
        margin: 0.5em 0 0;
      }
    }
  }
  &__fee {
    font-weight: 300;
    font-size: 0.875rem;
    display: block;
    margin: 0.25rem 0 0;
  }

  &__pay {
    width: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;

    &-icon {
      margin: 0 0.75rem 0 0;
      stroke-width: 1.5;
    }
    &-label {
      padding-top: 0.125rem;
    }
  }

  &__number {
    font-weight: normal;
  }
  &__title {
    flex-basis: 100%;
    font-size: 1.5em;
    margin: 0 0 2rem;
    font-weight: 400;

    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
    small {
      font-size: 1rem;
      display: block;
      position: relative;
      margin: 0 0 0 2.75rem;
    }
  }
  &__icon {
    vertical-align: -0.5rem;
    color: #004877;
    height: 2rem;
    width: 2rem;
    margin: 0 0.75rem 0 0;

    @include media-breakpoint-down(md) {
      height: 1.5em;
      width: 1.5rem;
      margin: 0 0.5rem 0 0;
    }
  }
  &__subtitle {
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0 0 1rem;
    padding: 0 0 0.25rem;
    border-bottom: 1px solid #e0e0e0;
  }

  &__unit {
    margin-bottom: 1rem;

    &-title {
      font-weight: 500;
      font-size: 1.25rem;
      margin: 0 0 1rem;
      padding: 0 0 0.25rem;
      border-bottom: 1px solid #eee;
    }
  }

  &__line-item {
    margin: 0 0 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-title {
      flex-grow: 1;
      font-weight: 400;
      display: flex;
      cursor: pointer;

      &:after {
        @include dotted-connect(1rem);
      }
    }
    &-price {
      font-weight: 500;
      padding: 0 0 0 0.25rem;
    }
    &-options.options {
      margin: 0.25rem 0 0 0.75rem;
    }
  }

  &__total {
    display: block;
    padding: 1rem 0 0;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: right;
    border-top: 2px solid #eee;
  }
  &__payed {
    margin: 0 0 0.5rem;
  }

  &__info-line {
    margin: 0 0 0.75rem;
  }
}
