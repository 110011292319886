@import '../../scss/variables';
@import '../../scss/mixins';

.unit-selector {
  display: flex;
  align-items: stretch;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    margin: 0 -0.25rem 0 0;
  }

  &__unit {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 10%;
    margin: 0 0 0 0.5rem;
    line-height: 1;
    text-align: center;
    font-weight: 200;

    @include media-breakpoint-down(md) {
      min-width: 70%;
      margin: 0 0.25rem 0.25rem 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
.unit {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0 2rem;
  text-align: center;

  @include media-breakpoint-down(md) {
    transition: none;
  }

  &__image {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
    background-size: cover;
    background-position: center;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
    background: #000;
    opacity: 0.3;
  }

  &:hover {
    flex-grow: 1.2;

    &__image {
      transform: scale(1.2);
    }
    &:after {
      opacity: 0.1;
    }
  }

  &__name {
    font-size: 2rem;
    color: #fff;
    position: relative;
    z-index: 2;

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }

  &--active {
    flex-grow: 3;

    @include media-breakpoint-down(md) {
      order: 2;
      height: 4rem;
    }

    &:hover {
      flex-grow: 3;
    }
  }
  &--other {
    @include media-breakpoint-down(md) {
      min-width: 40%;
      height: 3.5rem;
    }
    .unit__name {
      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }
    }
    &:after {
      opacity: 0.7;
    }
  }
}
