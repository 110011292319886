@import "../../scss/variables";
@import "../../scss/mixins";

.unit-calendar {
  margin: .5em 0 0;
  background: #eee;

  @include media-breakpoint-down(md) {
    margin: .25rem 0 0;
  }

  &__head {
    display: flex;
    align-items: center;
    height: 4.5rem;
    background: #eee;
    padding: 0 1.5rem;

    @include media-breakpoint-down(lg) {
      height: 3.5rem;
      padding: 0 .75rem;
    }

    @include media-breakpoint-down(md) {
      height: 3.5rem;
      padding: 0 .75rem;
    }
  }

  &__title {
    flex-grow: 1;
    font-size: 2rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }

  &__content {
    display: flex;
    align-items: stretch;
    clear: both;
    background: #fff;
    border-top: .5rem solid #fff;
    flex-grow: 1;
    position: relative;
  }

  &__days {
    display: flex;
    flex-grow: 1;
    padding: 4rem 0 0;

    @include media-breakpoint-down(lg) {
      padding: 3rem 0 0;
      overflow: scroll;
    }
  }

  &__day {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 10%;
    position: relative;
    min-width: 14.28%;

    @include media-breakpoint-down(md) {
      min-width: 28%;
    }
  }
  &__labels {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 4rem 0 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: #eee;
      height: 4rem;
      border-bottom: 2px solid #fff;

      @include media-breakpoint-down(lg) {
        height: 3rem;
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 3rem 0 0;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    background: #eee;
    color: #000;
    font-size: .875rem;
    border-bottom: 1px solid #fff;
    padding: .25rem;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      font-size: .75rem;
    }
  }
}
.pager {
  display: flex;
  align-items: center;
  font-size: 1.25rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  &__interval {
    padding: 0 1rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-down(md) {
      padding: 0 .75rem;
      font-size: 1rem;
    }
  }

  &__button {
    height: 100%;
    background: #444;
    border: none;
    color: #fff;
    width: 2.25rem;
    height: 2.25rem;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      background: #666;
    }

    &--disabled {
      cursor: default;
      background: #999; 
      
      &:hover {
        background: #999; 
      }
    }
  }
}