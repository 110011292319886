@import "../../scss/variables";
@import "../../scss/mixins";

.mini-cart {
  color: #000;
  background: #eee;
  display: flex;
  align-items: center;
  border-left: .5rem solid #fff;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &--active {
    cursor: pointer;
    background: #ffd200;

    @include media-breakpoint-down(md) {
      display: flex;
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    stroke-width: 1;
    margin: 1rem .5rem 1rem 1rem;

    @include media-breakpoint-down(lg) {
      height: 2rem;
      width: 2rem;
      margin: .75rem .5rem .75rem 1rem;
    }
  }

  &__content {
    padding: .5rem 1rem .5rem .5rem;

    @include media-breakpoint-down(lg) {
      padding: .5rem .5rem .5rem 0;
    }
    @include media-breakpoint-down(md) {
      flex-grow: 1;
    }
  }

  &__message {
    padding: 0 .5rem 0 0;
  }

  &__count {
    font-weight: 300;
  }

  &__total {
    font-weight: 500;
  }

  &__actions {
    align-self: stretch;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      flex-grow: 1;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      z-index: 2;
      transform: translate(0, -50%);
      border-left: 1rem solid #ffd200;
      border-top: 2.25rem solid transparent;
      border-bottom: 2.25rem solid transparent;
    }
  }

  &__checkout.button {
    height: 100%;
    background: #444;
    border: none;
    color: #fff;
    padding: 0 1rem 0 2rem;
    margin: 0;
    font-size: 1rem;

    @include media-breakpoint-down(lg) {
      padding: 0 .5rem 0 1.75rem;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }


  &:hover &__checkout.button {
    background: #666;
  }
}